body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-family: Cousine;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #171717;
  color: white;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  color: white;
}
a:visited {
  color: white;
}
a:hover {
  color: white;
}
a:active {
  color: white;
}

.Page {
  text-align: center;
}

// SNAKE LOADER
$snakeSegments: 10;

.snake-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 14px;
}
.snake-body {
  display: grid;
  gap: 4px;
  grid-auto-flow: column;
  .snake-segment {
    width: 11px;
    height: 11px;
    background: #007000;
    border-radius: 50%;
    margin: 4px 0;
  }
}
@for $i from 1 through $snakeSegments {
  $delay: 0.15 * $i;
  .snake-segment:nth-child(#{$snakeSegments + 1 - $i}) {
    animation: snake-segment 1s infinite;
    animation-delay: $delay + s;
  }
}
@keyframes snake-segment {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-16px);
  }
}
